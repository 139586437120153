.future-sale
    padding 104px 0
    @media mediumView
        padding 80px 0
    &__desc
        width 492px
        margin-bottom 100px
        @media mediumView
            margin-bottom 64px
            width 462px
        @media smallView
            width auto
            margin-bottom 48px
    &__list
        padding 0 70px
        @media mediumView
            flex-direction column
            padding 0 30px 40px
        @media smallView
            padding 0
    &__item
        @media largeView
            text-align center
            flex 0 0 305px
        @media mediumView
            flex-direction row
            &:not(:last-child)
                margin-bottom 48px
        @media smallView
            flex-direction column
            align-items center
            text-align center
        &-icon
            flex 0 0 60px
            margin-bottom 48px
            @media onlyMediumView
                flex 0 0 85px
                margin 0 55px 0 0
                align-self flex-start
            @media smallView
                flex 0 0 48px
                height 48px
                margin-bottom 24px
                svg
                    max-height 48px
        &-title
            margin-bottom 24px
            @media mediumView
                margin-bottom 16px
