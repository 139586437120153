.layout-header
    height 76px
    color white
    @media smallView
        height 45px
    &__logo
        @media mediumView
            position absolute
            left -25px
            top 50%
            transform translateY(-50%)
        @media smallView
            left -8px
            .logo-bot
                width 28px
        .logo-text
            margin 3px 0 0 10px
            @media smallView
                width 112px
                margin 2px 0 0 5px
    &__left
        position absolute
        top 0
        @media largeView
            left 30px
        @media mediumView
            right 15px
    &__right
        position absolute
        right 36px
        top 0
        @media mediumView
            right 72px
        .socials
            margin-left 36px
            @media screen and (max-width: 1350px)
                display none
    &__burger
        cursor pointer
        flex 0 0 40px
        width 40px
        height 40px
        @media largeView
            margin-right 15px
        &-lines
            flex 0 0 24px
            height 14px
            border-top 2px solid white
            border-bottom 2px solid white
            &:before
                content ''
                flex 0 0 2px
                width 100%
                background-color white
    &__tel
        font-size 20px
