.contacts-info
    color white
    @media largeView
        flex 0 0 490px
    @media mediumView
        align-items center
        width 100%
    &__title
        text-align left
        @media mediumView
            text-align center
            padding 0 100px
            margin-bottom 62px
        @media smallView
            margin-bottom 16px
            padding 0
            .botttom-panel &
                order -1
    &__img
        height 551px
        margin 0 0 37px
        .botttom-panel &
            display none
        @media smallView
            width 220px
            height 440px
            margin 0 0 30px
        &:before
            content ''
            @extend .wrapper-absolute
            box-shadow 0px 36px 48px rgba(0, 0, 0, 0.29)
            border-radius 80px
        &-star
            position absolute
            &.star-1
                left -53px
                top 164px
            &.star-2
                right 5px
                top -33px
            &.star-3
                right -90px
                top 85px
        &-message
            position absolute
            right -146px
            top 284px
    &__img-bot
        .example-section &
            display none
        @media mediumView
            width 405px
    &__bots
        width 359px
        padding 18px 0 23px
        background-color #195AAD
        border-radius 16px
        @media smallView
            width 239px
            .botttom-panel &
                order -1
                margin 10px 0 24px
        &-label
            line-height 28px
            margin-bottom 25px
            @media smallView
                line-height 20px
                margin-bottom 16px
    &__desc
        margin 40px 0 30px
        width 440px
        line-height 30px
        @media mediumView
            text-align center
        @media smallView
            width 100%
            margin 16px 0
            line-height 22px
    .green-button
        @media largeView
            align-self flex-start