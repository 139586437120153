.botttom-panel
    background-color blueColor
    color white
    &__top
        padding 70px 0 140px
        @media mediumView
            padding 48px 0
    &__img
        animation 3s botUpDownMove infinite linear
        margin 0 65px 0 30px
    .contacts-info
        @extend .self-end