.example-section
    padding 75px 0
    background-color blueColor
    @media mediumView
        padding 48px 0 37px
    @media smallView
        padding 24px 0
    &__img
        height 551px
        margin 0 213px 0 80px
        flex 0 0 275px
        &:before
            content ''
            @extend .wrapper-absolute
            box-shadow 0px 36px 48px rgba(0, 0, 0, 0.29)
            border-radius 80px
        &-star
            position absolute
            &.star-1
                left -53px
                top 164px
            &.star-2
                right 5px
                top -33px
            &.star-3
                right -90px
                top 85px
        &-message
            position absolute
            left -146px
            top 350px