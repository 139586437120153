.what-for-bot
    padding 104px 0 60px
    @media mediumView
        padding 80px 0
    @media smallView
        padding 48px 0
    &__desc
        margin-bottom 100px
        width 458px
        @media mediumView
            margin-bottom 48px
        @media smallView
            width 100%
    &__list
        text-align center
        width 1100px
        @media mediumView
            flex-direction column
            width 100%
        @media onlyMediumView
            text-align left
            padding 0 40px
    &__item
        @media largeView
            flex 0 0 310px
        @media mediumView
            flex-direction row
            &:not(:last-child)
                margin-bottom 48px
        @media smallView
            flex-direction column
            align-items center
        &-icon
            flex 0 0 65px
            margin-bottom 45px
            @media mediumView
                margin 0 55px 0 0
                align-self flex-start
            @media smallView
                flex 0 0 auto
                margin 0 0 24px
                align-self center
                svg
                    max-height 48px
        &-title
            margin-bottom 24px
            @media mediumView
                margin-bottom 20px