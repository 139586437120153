.partners-section
    padding 64px 0
    @media smallView
        padding 48px 0
    &__title
        margin-bottom 64px
        @media onlyMediumView
            margin-bottom 26px
        @media smallView
            margin-bottom 54px
    &__list
        @media mediumView
            flex-wrap wrap
            justify-content center
        @media smallView
            flex-direction column
            align-items center
    &__partner
        &:first-child
            max-width 233px
            @media mediumView
                max-width 168px
        &:nth-child(2)
            max-width 182px
            @media mediumView
                max-width 132px
        &:nth-child(3)
            max-width 118px
            @media mediumView
                max-width 79px
        &:nth-child(4)
            max-width 178px
            @media mediumView
                max-width 138px
        &:last-child
            max-width 227px
            @media mediumView
                max-width 156px
        @media mediumView
            margin 38px 20px 0
        @media smallView
            margin 0 30px
            &:not(:last-child)
                margin-bottom 30px