@font-face
    font-family 'PT Astra Sans'
    src local('PT Astra Sans'),
        local('../fonts/PTAstraSans-Regular'),
        url('../fonts/Ptastrasans.woff2') format('woff2'),
        url('../fonts/Ptastrasans.woff') format('woff'),
        url('../fonts/Ptastrasans.ttf') format('truetype')
    font-weight 400
    font-style normal

@font-face
    font-family 'PT Astra Sans Bold'
    src local('PT Astra Sans Bold'),
        local('../fonts/PTAstraSans-Bold'),
        url('../fonts/Ptastrasansbold.woff2') format('woff2'),
        url('../fonts/Ptastrasansbold.woff') format('woff'),
        url('../fonts/Ptastrasansbold.ttf') format('truetype')
    font-weight 700
    font-style normal

