@import 'utils/icons'
@import 'utils/fonts'
@import 'utils/vars'
@import 'utils/flexbox'
@import 'utils/extends'
@import 'utils/mixins'
@import 'libs'

*
    margin 0
    padding 0

*, *:before, *:after
	box-sizing border-box

html
    height 100%
    min-height 100%

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary
    display block

body
    font 24px fontAstra, Tahoma, sans-serif
    height 100%
    color black
    line-height 1
    min-height 100vh
    overflow-x hidden
    @media smallView
        font-size 16px
    &.no-scroll
        overflow hidden
        @media largeView
            padding-right 17px

h1, h2, h3, h4, h5, h6
    margin 0
    font-family fontAstraBold

b, strong
    font-family fontAstraBold

a
    color black
    text-decoration none
    &:hover
        text-decoration underline

ul, li
	list-style-type none
	margin 0
	padding 0

p
    margin 0

img
    border none
    max-width 100%
    height auto

audio,
canvas,
iframe,
img,
svg,
video
	vertical-align middle

input,
textarea,
select,
button
    outline none
    vertical-align middle
    font-family fontAstra
    font-size 24px
    @media smallView
        font-size 16px

.layout-body
    // height 100vh
    // min-height 100vh
    &:before
        content ''
        @extend .wrapper-absolute
        position fixed
        z-index -10
        background-color rgba(black, 0)
        transition background-color 200ms
    -webkit-appearance none
    // @media mediumView
    //     height calc(var(--vh, 1vh) * 100)
    //     min-height calc(var(--vh, 1vh) * 100)
    .no-scroll &
        user-select none
        pointer-events none
        &:before
            z-index 10
            background-color rgba(black, .5)


.content-wrapper
    min-width 320px
    flex 1 0 auto
    overflow hidden

.content-center
    max-width 1280px
    margin 0 auto
    height 100%
    position relative
    // border-left 1px solid red
    // border-right 1px solid red
    box-sizing content-box
    @media mediumView
        margin 0 55px
        // border-left 1px solid red
        // border-right 1px solid red
    @media smallView
        margin 0 24px

.media-youtube-video
    @media mediumView
        width 100%
        position: relative
        padding-bottom: 56.25%
        height: 0 !important
        overflow: hidden


.media-youtube-video iframe,
.media-youtube-video object,
.media-youtube-video embed
    @media mediumView
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%


//modules
@import "modules/**/*.styl"

// Pages
// Main page
@import 'pages/index/index.styl'