.faq-section
    padding 64px 0
    background-color blueColor
    color white
    @media mediumView
        padding 48px 0
    @media smallView
        padding 24px 0
        .icon-gray-bot
            width 45px
    &__title
        width 1000px
        margin 56px 0 68px
        @media mediumView
            width 100%
            margin 48px 0
        @media smallView
            margin 24px 0 36px
    &__list
        @media largeView
            width 756px
    &__item
        border 1px solid rgba(white, .2)
        border-radius 10px
        padding 36px 80px 36px 40px
        font-size 20px
        &:not(:last-child)
            margin-bottom 16px
        @media smallView
            padding 8px 8px 16px
            font-size 16px
    &__question
        font-size 24px
        line-height 32px
        margin 0 0 24px 145px
        @media smallView
            font-size 16px
            line-height 22px
            margin 0 0 16px
    &__info
        @media smallView
            flex-direction column
    &__label
        flex 0 0 145px
        font-size 20px
        color rgba(white, .5)
        @media smallView
            flex 0 0 auto
            font-size 16px
            margin-bottom 8px