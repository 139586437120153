.examples-bots
    background-color grayBg
    color white
    padding 64px 0 62px
    @media smallView
        padding 36px 0 26px
    &__title
        margin-bottom 34px
        @media smallView
            margin-bottom 20px
    &__content
        flex 0 0 680px
        @media mediumView
            flex 0 0 765px
        @media smallView
            flex 0 0 665px
        .hand-img
            position absolute
            bottom 80px
            left 50%
            margin-left -165px
            @media smallView
                width 540px
                margin-left -140px
                bottom 0
    &__tel
        position absolute
        top 0
        left 50%
        transform translateX(-50%)
        &:before
            content ''
            position absolute
            left 50%
            transform translateX(-50%)
            top 17px
            width 249px
            height 569px
            border-radius 60px
            box-shadow 0px 36px 48px rgba(0, 0, 0, 0.29)
            z-index -1
            @media smallView
                width 208px
                height 475px
        img
            width 383px
            min-width 383px
            @media smallView
                width 320px
                min-width 320px
    &__slider
        padding-top 45px
        margin-bottom 24px
        width 920px
        @media mediumView
            width 100%
        @media smallView
            padding-top 38px
        .owl-item
            animation-duration 200ms
        &-item
            width 920px
            @media mediumView
                width 100%
            &-top
                margin-bottom 77px
                @media mediumView
                    justify-content center
                @media smallView
                    margin-bottom 67px
            &-title
                font-size 28px
                line-height 28px
                @media mediumView
                    margin 0 35px
                @media smallView
                    margin 0
                    font-size 16px
                    line-height 28px
        &-video
            width 260px
            overflow hidden
            border-radius 20px
            &:not(:hover) .pause
                display none
            video
                width 100%
            @media smallView
                width 216px
            &-button
                cursor pointer
                width 64px
                height 64px
                border-radius 50%
                position absolute
                left 50%
                top 50%
                transform translate(-50%, -50%)
                background-color greenColor
                &:hover
                    background-color rgba(greenColor, .7)
                &:not(.pause)
                    padding-left 5px
                    &:after
                        content ''
                        width 0
                        height 0
                        border-style solid
                        border-width 10px 0 10px 20px
                        border-color transparent transparent transparent white
                &.pause
                    &:before, &:after
                        content ''
                        flex 0 0 3px
                        height 24px
                        background-color white
                        margin 0 3px
        &-prev
            cursor pointer
            user-select none
            .examples-bots__slider-item-top &
                flex 0 0 300px
            &-title
                text-align center
                font-size 28px
                line-height 28px
                color blueColor
                flex 0 0 240px
        &-next
            cursor pointer
            user-select none
            .examples-bots__slider-item-top &
                flex 0 0 300px
            &-title
                text-align center
                font-size 28px
                line-height 28px
                color blueColor
                flex 0 0 240px
            svg
                transform scale(-1, 1)
        .owl-dots
            position absolute
            left 50%
            transform translateX(-50%)
            top 565px
            @extend .box-row
            @media smallView
                top 470px
            .owl-dot
                width 8px
                height 8px
                border 1px solid grayColor
                border-radius 50%
                margin 0 7px
                &.active
                    border-color grayColor2
                    background-color grayColor2
    &__desc
        color rgba(white, .5)
        width 492px
        text-align center
        @media smallView
            width 100%