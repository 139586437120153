.relative
    position relative

.full-height
    height 100%

.full-width
    width 100%

.wrapper-absolute
    position absolute
    width 100%
    height 100%
    left 0
    top 0

.text-center
    text-align center

// modules
.socials
    &__link
        &:hover
            opacity .7
        &:not(:last-child)
            margin-right 12px

.bot-img-list
    .top-panel__messages-item &
        margin-top 24px
    &__bot
        flex 0 0 56px
        width 56px
        height 56px
        &-link
            @media largeView
                .bot-img-list__bot:hover &
                    z-index 2
                    &:after
                        content ''
                        position absolute
                        left 50%
                        transform translateX(-50%)
                        bottom -10px
                        width 0
                        height 0
                        border-style solid
                        border-width 0 13.5px 12px 13.5px
                        border-color transparent transparent greenColor transparent
                    svg
                        opacity 0.8
                    &.tg
                        background-color tgColorHover
                    &.vb
                        background-color vbColorHover
                    &.vk
                        background-color vkColorHover
                    &.fb
                        background-color fbColorHover
        &-link
            border-radius 16px
            &.tg
                background-color tgColor
            &.vb
                background-color vbColor
            &.vk
                background-color vkColor
            &.fb
                background-color fbColor
            @media smallView
                border-radius 8px
        @media smallView
            flex 0 0 40px
            width 40px
            height 40px
            svg
                width 23px
            .icon-bot-telegram
                width 34px
        &:not(:last-child)
            margin-right 16px
            @media smallView
                margin-right 5px
    &__tooltip
        position absolute
        top 65px
        left -60px
        background-color greenColor
        color white
        font-size 20px
        padding 14px 24px
        width 436px
        border-radius 4px
        box-shadow 0 0 10px rgba(black, .2)
        transition all 100ms
        .bot-img-list__bot:nth-child(2) &
            width 520px
        .bot-img-list__bot:not(:hover) &
            opacity 0
            visibility hidden
        &-title
            line-height 28px
            margin-bottom 8px
        &-desc
            line-height 24px
        img
            margin-left 50px

// buttons
.green-button
    height 60px
    border none
    cursor pointer
    color white
    background-color greenColor
    border-radius 36px
    padding 0 16px
    font-size 24px
    font-family fontAstraBold
    padding-bottom 2px
    &:hover
        background-color rgba(greenColor, .7)
    @media smallView
        height 40px
        font-size 16px
        padding 0 20px
        border-radius 24px

// text samples
.block-title
    font-size 48px
    line-height 55px
    text-align center
    margin-bottom 24px
    @media smallView
        font-size 24px
        line-height 28px
        margin-bottom 16px

.block-desc
    font-size 28px
    line-height 32px
    text-align center
    color rgba(black, .5)
    @media smallView
        font-size 20px
        line-height 24px

.static-text
    line-height 28px
    @media smallView
        line-height 22px

.list-item-title
    font-size 28px
    line-height 32px
    @media smallView
        font-size 16px
        line-height 22px


// animations
@keyframes botUpDownMove
    50%
        transform translateY(5%)

@keyframes messageView
    from
        max-height 0
        opacity 0
        font-size 0
        overflow hidden
        transform scale(0)
    to
        max-height 200px
        opacity 1
        font-size 24px
        overflow inherit
        transform scale(1)


// @media samples
.small-view
    @media mediumNoView
        display none

.medium-view
    @media largeView
        display none

.large-view
    @media smallView
        display none !important

.big-large-view
    @media mediumView
        display none

.tablet-column
    @media mediumView
        flex-direction column

.mobile-column
    @media smallView
        flex-direction column