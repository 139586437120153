.top-panel
    background-color blueColor
    min-width 320px
    &__wrapper
        @media mediumView
            flex-direction column
            align-items center
        @media smallView
            padding-bottom 40px
    &__content
        padding 70px 0 80px
        @media mediumView
            padding 40px 0 64px
        @media smallView
            padding 15px 0 30px
        .green-button
            margin-top 24px
            @media largeView
                align-self flex-start
            @media smallView
                position absolute
                left 50%
                bottom 0
                margin 0
                transform translateX(-50%)
                white-space nowrap
    &__img
        @media largeView
            animation 3s botUpDownMove infinite linear
            margin 20px 40px 0 20px
        @media mediumView
            align-self center
            width 402px
            margin-left -40px
        @media smallView
            margin 16px 0 0
    &__info
        @media largeView
            flex 0 0 359px
            min-height 596px
            align-self flex-end
            justify-content flex-end
        @media mediumView
            width 359px
            align-items center
        @media smallView
            order -1
            width 100%
            padding 0 16px
    &__messages
        margin-bottom 16px
        @media smallView
            margin-bottom 8px
        &-item
            background-color white
            border-radius 4px
            box-shadow 0px 1px 2px rgba(31, 108, 175, 0.17)
            line-height 28px
            .text
                padding 16px
            @media largeView
                max-height 0
                overflow hidden
                font-size 0
                animation .3s messageView linear
                animation-fill-mode forwards
            @media smallView
                line-height 20px
            &:first-child
                border-top-left-radius 16px
                border-top-right-radius 16px
                @media largeView
                    animation-delay 1s
                & + li
                    margin 4px 0
                    @media largeView
                        animation-delay 3s
            &:last-child
                border-bottom-right-radius 16px
                @media largeView
                    animation-delay 5s