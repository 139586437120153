.icon-back-arrow {
    width: 19px;
    height: 16px;
}
.icon-blue-logo {
    width: 91px;
    height: 48px;
}
.icon-bot-fb {
    width: 32px;
    height: 14px;
}
.icon-bot-telegram {
    width: 48px;
    height: 48px;
}
.icon-bot-viber {
    width: 32px;
    height: 33px;
}
.icon-bot-vk {
    width: 38px;
    height: 23px;
}
.icon-checkbox {
    width: 8px;
    height: 6px;
}
.icon-closed-menu {
    width: 17px;
    height: 16px;
}
.icon-closed-modal {
    width: 17px;
    height: 16px;
}
.icon-fb {
    width: 40px;
    height: 40px;
}
.icon-footer-logo {
    width: 129px;
    height: 17px;
}
.icon-future-item-1 {
    width: 83px;
    height: 58px;
}
.icon-future-item-2 {
    width: 66px;
    height: 60px;
}
.icon-future-item-3 {
    width: 56px;
    height: 60px;
}
.icon-gray-bot {
    width: 65px;
    height: 34px;
}
.icon-hand {
    width: 651px;
    height: 592px;
}
.icon-list-check {
    width: 37px;
    height: 27px;
}
.icon-logo {
    width: 181px;
    height: 25px;
}
.icon-logo-bot {
    width: 46px;
    height: 25px;
}
.icon-logo-text {
    width: 194px;
    height: 24px;
}
.icon-slider-arrow {
    width: 37px;
    height: 16px;
}
.icon-vk {
    width: 40px;
    height: 40px;
}
.icon-what-for-1 {
    width: 64px;
    height: 61px;
}
.icon-what-for-2 {
    width: 67px;
    height: 45px;
}
.icon-what-for-3 {
    width: 60px;
    height: 64px;
}
.icon-youtube {
    width: 40px;
    height: 40px;
}
