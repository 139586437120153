.layout-footer
    min-width 320px
    overflow hidden
    padding-bottom 33px
    &__copy
        width 573px
        font-size 20px
        line-height 28px
        text-align center
        margin-top 24px
        color grayLight
        @media smallView
            width 100%
            font-size 16px
            line-height 22px
            margin-top 15px
        a
            color grayLight
            text-decoration underline
            &:hover
                text-decoration none