.need-business
    padding 104px 0
    @media mediumView
        padding 80px 0 60px
    @media smallView
        padding-top 48px
        .icon-gray-bot
            width 45px
    &__title
        margin-top 56px
        @media smallView
            margin-top 24px
    &__desc
        width 492px
        margin-bottom 104px
        @media mediumView
            margin-bottom 64px
        @media smallView
            width 100%
            margin-bottom 24px
    &__list
        width 1000px
        margin-bottom -90px
        @media onlyMediumView
            width 100%
        @media smallView
            width 100%
            flex-direction column
            margin-bottom -24px
        &-item
            margin-bottom 90px
            @media largeView
                flex 0 0 380px
            @media onlyMediumView
                flex-direction column
                align-items center
                flex 0 0 45%
                text-align center
            @media smallView
                margin-bottom 24px
            svg
                margin-right 16px
                @media onlyMediumView
                    margin 0 0 36px
                @media smallView
                    width 24px
        &-title
            margin-bottom 8px
            word-break break-word