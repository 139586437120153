.menu-panel
    position fixed
    z-index 10
    color white
    top 0
    left 0
    width 320px
    height 100vh
    background-color grayBg
    padding 24px 36px 48px
    animation-duration 200ms
    &:not(.active)
        display none
    &__link
        cursor pointer
        user-select none
        &:not(:last-child)
            margin-bottom 24px
        &:hover
            opacity 0.8
        @media smallView
            font-size 24px
            line-height 28px
    &__bottom
        font-size 20px
        line-height 23px
    &__tel
        margin 36px 0 16px
    &__copy
        color grayLight
        a
            color grayLight
            text-decoration underline
            &:hover
                text-decoration none
    &__closed
        cursor pointer
        position absolute
        top 24px
        right 24px
