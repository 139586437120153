.modal-block
    position fixed
    z-index 10
    left 50%
    top 50%
    width 756px
    height 684px
    background-color white
    border-radius 24px
    margin-top calc(-684px/2)
    margin-left calc(-756px/2)
    &:not(.active)
        display none
    &.sucess-view
        height 323px
        margin-top calc(-323px/2)
        @media smallView
            margin-top 0
            height 100vh
    @media mediumView
        width 560px
        margin-left calc(-560px/2)
    @media smallView
        width 100%
        margin-left 0
        margin-top 0
        border-radius 0
        height 100vh
        left 0
        top 0
    &__order
        padding-top 64px
        animation-duration 200ms
        @media smallView
            height 100%
            padding 56px 36px 0
            & > svg
                width 45px
        &:not(.active)
            display none
    &__title
        font-size 36px
        line-height 41px
        margin 56px 0 16px
        @media smallView
            font-size 24px
            line-height 28px
            margin 24px 0 8px
    &__desc
        width 492px
        margin-bottom 37px
        @media smallView
            width 100%
    &__form
        width 503px
        @media smallView
            width 100%
        &-item
            &:first-child
                margin-bottom 16px
        &-label
            font-size 16px
            line-height 18px
            margin-bottom 8px
        &-text
            height 46px
            border-radius 4px
            border 1px solid rgba(128, 128, 128, 0.3)
            padding 0 8px
            &.error
                border-color rgba(255, 108, 108, 0.5)
            +placeholder()
                color rgba(black, .3)
                opacity 1
            &:focus
                +placeholder()
                    opacity 0
        &-error
            font-size 16px
            line-height 18px
            color redColor
            margin-top 2px
            input:not(.error) + &
                display none
            @media smallView
                font-size 14px
                line-height 16px
        &-condition
            margin 8px 0 44px
            font-size 16px
            line-height 18px
            @media smallView
                flex-direction column
                align-items flex-start
            input
                display none
            &-label
                margin-right 5px
            &-checkbox
                flex 0 0 16px
                height 16px
                width 16px
                background-color greenColor
                border-radius 2px
                margin-right 8px
                cursor pointer
                input:not(:checked) + label & svg
                    display none
            &-link
                cursor pointer
                user-select none
                color blueColor
                &:hover
                    text-decoration underline
                @media smallView
                    margin-left 25px
        &-submit
            width 240px
            &[disabled]
                cursor default
                opacity 0.5
            @media smallView
                height 60px
                border-radius 36px
                padding 0 16px
                font-size 24px
    &__conditions
        animation-duration 200ms
        @media smallView
            height 100%
            padding 48px 0 36px
            display flex
            flex-direction column
        &:not(.active)
            display none
        h3
            text-align center
            margin 64px 0 40px
            @media smallView
                margin 0 0 24px
        &-text
            height 500px
            margin 0 16px 0 56px
            overflow-y auto
            overflow-x hidden
            line-height 30px
            padding-right 30px
            p:not(:last-child)
                margin-bottom 20px
            @media smallView
                margin 0 8px 0 24px
                line-height 18px
                padding-right 10px
                height auto
                flex 1 1 auto
            &::-webkit-scrollbar
                width 9px
                background-color #E9F1FB
                border-radius 4px
            &::-webkit-scrollbar-thumb
                width 9px
                border-radius 4px
                background-clip padding-box
                background-color blueColor
        &-back
            cursor pointer
            position absolute
            top 16px
            left 16px
            width 40px
            height 40px
            border-radius 50%
            background-color rgba(30, 107, 206, 0.1)
    &__success
        padding-top 64px
        animation-duration 200ms
        &:not(.active)
            display none
        @media smallView
            height 100%
            justify-content center
            text-align center
            padding 0
        &-title
            margin 56px 0 16px
            font-size 36px
            line-height 41px
        &-desc
            font-size 28px
            line-height 32px
            color rgba(black, .5)
    &__closed
        cursor pointer
        position absolute
        top 16px
        right 16px
        width 40px
        height 40px
        border-radius 50%
        background-color rgba(30, 107, 206, 0.1)